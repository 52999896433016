import React from 'react';
import styles from './regeneratecard.module.css';

const StarIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF" className="w-6 h-6">
        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" />
      </svg>     
    );
  };


  const RegenerateCard = ({document_id, handleFlashcardCreation}) => {
    return (
        <div className={styles.uploadContainer}>
          <div className={styles.iconCircle}>
          <span className={styles.uploadIcon}>
              <StarIcon/>
          </span>
          </div>
          <h3 className={styles.uploadTextSubtitle}>
            Congratulations for upgrading to a premium plan
          </h3>
          <div className={styles.uploadArea}>
            Choose the desired language of your full set of flashcards
            <div className={styles.buttons}>
              <button className={styles.uploadBtn} onClick={() => handleFlashcardCreation(document_id, "English")}>
                English
              </button>
              <button className={styles.uploadBtn} onClick={() => handleFlashcardCreation(document_id, "German")}>
                German
              </button>
            </div>
        </div>
        </div>
      );
}

export default RegenerateCard;
const folderUpLightIcon = (props) => {
    /* style={{marginRight: spacing + 'em'}}
    * style="mask-type:alpha"
    *  */
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
            <mask id="mask0_67_185" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="65"
                  height="65">
                <rect width="65" height="65" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_67_185)">
                <path
                    d="M12 19C12 17.1144 12 16.1716 12.5858 15.5858C13.1716 15 14.1144 15 16 15H23.3431C24.1606 15 24.5694 15 24.9369 15.1522C25.3045 15.3045 25.5935 15.5935 26.1716 16.1716L29.3284 19.3284C29.9065 19.9065 30.1955 20.1955 30.5631 20.3478C30.9306 20.5 31.3394 20.5 32.1569 20.5H48C49.8856 20.5 50.8284 20.5 51.4142 21.0858C52 21.6716 52 22.6144 52 24.5V46C52 47.8856 52 48.8284 51.4142 49.4142C50.8284 50 49.8856 50 48 50H16C14.1144 50 13.1716 50 12.5858 49.4142C12 48.8284 12 47.8856 12 46V19Z"
                    stroke="url(#paint0_linear_67_185)" stroke-width="3"/>
                <path d="M32 41V29M32 29L27.5 33M32 29L36.5 33" stroke="url(#paint1_linear_67_185)" stroke-width="3"
                      stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_67_185" x1="15.6362" y1="10.6243" x2="78.5078" y2="-5.07467"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF"/>
                    <stop offset="0.5" stop-color="#80A9F9"/>
                    <stop offset="1" stop-color="white"/>
                </linearGradient>
                <linearGradient id="paint1_linear_67_185" x1="28.3184" y1="27.5005" x2="44.404" y2="21.3635"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF"/>
                    <stop offset="0.5" stop-color="#80A9F9"/>
                    <stop offset="1" stop-color="white"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default folderUpLightIcon;
import React from "react";
import styles from "./upgradecardeditor.module.css";

const UpgradeCardEditor = ({ toggleUpgradeModal }) => {
    return (
        <div className={styles.container}>
            <div className={styles.uploadTextSubtitle}>
                This is a summary of the first 1000 words of your document.
                <br></br>
                <br></br>
                Upgrade to get unlimited summaries
            </div>
            <button className={styles.uploadBtn} onClick={toggleUpgradeModal}>
                Upgrade now
            </button>
        </div>
    );
};

export default UpgradeCardEditor;

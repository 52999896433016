import React from 'react';
import styles from './resetbutton.module.css'; 

const DeleteButtonFileManager = ({ isVisible, onClose, onReset, headerMessage, message}) => {
  if (!isVisible) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.window}>
        <header className={styles.header}>
          <span>{headerMessage}</span>
          <button onClick={onClose}>&times;</button>
        </header>
        <div className={styles.content}>
          {message.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <div className={styles.buttonContainer}>
            <button onClick={onReset} className={`${styles.actionButton} ${styles.redButton}`}>Accept</button>
            <button onClick={onClose} className={`${styles.actionButton} ${styles.blueButton}`}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteButtonFileManager;

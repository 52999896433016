import React from "react";
import styles from "./feedbackcard.module.css";

const FeedbackCardEditor = ({ toggleFeedback, setFeedbackMode }) => {
    return (
        <div className={styles.container}>
            <div className={styles.uploadTextSubtitle}>
                We made a lot of effort to support your summaries with images.
                <br></br>
                <br></br>
                Please share your Feedback with us!
            </div>
            <button
                className={styles.uploadBtn}
                onClick={() => {
                    setFeedbackMode("multimedia_summary");
                    toggleFeedback();
                }}>
                Feedback
            </button>
        </div>
    );
};

export default FeedbackCardEditor;

import React, { useCallback, useMemo, useRef, useEffect, useState} from 'react'
import axios from 'axios';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import { Editor } from "@tinymce/tinymce-react";
import styles from './flashcard.module.css';
import studystyles from './flashcardstudymode.module.css'
import ClipLoader from "react-spinners/ClipLoader";

const TrashIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#494949" className="w-6 h-6">
    <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
  </svg>
);

const SaveIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#494949" className={styles['svg-icon']}>
        <path d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/>
    </svg>
);

const CheckIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#494949" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
);

const TinyEditor = ({text, setText, isReadOnly}) => {
  const editorRef = useRef(null);
  const isEditable = !isReadOnly

  const base64_img_handler = (blobInfo) => new Promise((resolve) => {
    resolve("data:image/png;base64," + blobInfo.base64());
  });

  return (
    <div className={styles.cardcontainer}>
      <div className={styles.wrapper}>
        <div className={styles['editable-container']}>
          <div className={styles['demo-dfree']}>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                licenseKey='gpl'
                initialValue={text}
                init={{
                    plugins: [ 'quickbars', 'lists', 'image', 'table'],
                    toolbar: false,
                    menubar: false,
                    inline: true,
                    quickbars_selection_toolbar: 'bold italic underline numlist bullist quickimage quicktable alignleft aligncenter alignright',
                    quickbars_insert_toolbar: false,
                    autosave_ask_before_unload: true,
                    editable_root: isEditable,
                    images_upload_handler: base64_img_handler,
                }}
                onBlur={() => {setText(editorRef.current.getContent())
                }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const EditCard = ({headline, isTextFocused, setIsTextFocused, handleChange, card, text, setText, saveCard, deleteCard, cardString, savingCardIds, finishedSavingCardIds}) => {
  return (
      <div className={styles.container} onBlur={saveCard}>
          <div className={styles.content}>
              <div className={styles.card}>
                  <div className={styles.headline}>
                  <textarea
                      className={styles.input}
                      value={headline}
                      onChange={(e) => handleChange(e)}
                      placeholder="Title of your card"
                      rows="1"
                  ></textarea>
                  </div>
                  <div
                      className={styles.text}
                      tabIndex="0" // Make the div focusable
                      onFocus={() => setIsTextFocused(true)}
                      onBlur={() => {setIsTextFocused(false)}}
                  >
                      <TinyEditor text={text} setText={setText}></TinyEditor>
                  </div>
              </div>

              <div className={styles.rightColumn}>
                <div className={styles.number}>
                  {cardString}
                </div>
                <div className={styles.icons}>
                    {
                        savingCardIds.includes(card.id) ?
                            <span className={styles.icon}><ClipLoader color='#494949' className={styles.cliploader} /></span> :
                            finishedSavingCardIds.includes(card.id) ?
                                <span className={styles.icon}>{CheckIcon}</span> :
                                <span className={styles.icon} onClick={saveCard}>{SaveIcon}</span>
                    }
                    <div className={styles.separator}></div>
                    <span className={styles.icon} onClick={deleteCard}>{TrashIcon}</span>
                </div>
              </div>
          </div>
      </div>
  )
}

const StudyCard = ({headline, isTextFocused, setIsTextFocused, numberOfRepetitions, fullView, handleChange, card, text, setText, saveCard, deleteCard, cardString, savingCardIds, finishedSavingCardIds}) => {
  return (
      <div className={studystyles.container}>
          <div className={studystyles.content}>
              <div className={studystyles.card}>
              {fullView ? (
                <div className={studystyles.placeholder}>
                  <div className={studystyles.headline}>
                    <span className={studystyles.input}>
                      {headline || "Title of your card"}
                    </span>
                  </div>
                  <div
                    className={studystyles.text}
                    tabIndex="0" 
                    onFocus={() => setIsTextFocused(true)}
                    onBlur={() => setIsTextFocused(false)}
                  >
                    <TinyEditor text={text} setText={setText} isReadOnly={true}></TinyEditor>
                  </div>
                </div>
              ) : (
                <div className={studystyles.placeholder}>
                  <div className={studystyles.headlineLarge}>
                      {headline || "Title of your card"}
                    </div>
                </div>
              )}
              </div>
              <div className={studystyles.rightColumn}>
                <div className={studystyles.number}>
                  {cardString}
                </div>
                <div className={studystyles.number}>
                  Correct: <br></br>
                  {card.correct}/{numberOfRepetitions}
                </div>
              </div>
          </div>
      </div>
  )
}


const Flashcard = ({card, isStudyModeActive, modifyCard, savingCardIds, finishedSavingCardIds, cardNumber, numberOfCards, numberOfRepetitions, fullView}) => {
    const [headline, setHeadline] = useState(card.headline);
    const [text, setText] = useState(card.content);
    const [isTextFocused, setIsTextFocused] = useState(false);

    const cardString = `${cardNumber}/${numberOfCards}`
    const handleChange = (e) => {
        setHeadline(e.target.value);
    }

    const useLazyLoad = (ref, options = {}) => {
      const [isVisible, setIsVisible] = useState(isStudyModeActive ? true : false);
    
      useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              try{
                observer.unobserve(ref.current); // Stop observing once loaded
              } catch{
              }
            }
          });
        }, options);
    
        if (ref.current) {
          observer.observe(ref.current);
        }
    
        return () => {
          if (ref.current) {
            observer.disconnect();
          }
        };
      }, [ref, options]);
    
      return isVisible;
    };

    const ref = useRef();
    const isVisible = useLazyLoad(ref);

    const saveCard = () => {
        modifyCard(card.id, 'saveCard', headline, text);
    }

    const deleteCard = () => {
        modifyCard(card.id, 'deleteCard');
    }
    

    return (
      <div ref={ref} className={styles.wrapper}>
        {isVisible && (
          !isStudyModeActive ? (
            <EditCard
              headline={headline}
              text={text}
              setText={setText}
              isTextFocused={isTextFocused}
              setIsTextFocused={setIsTextFocused}
              handleChange={handleChange}
              card={card}
              saveCard={saveCard}
              deleteCard={deleteCard}
              cardString={cardString}
              savingCardIds={savingCardIds}
              finishedSavingCardIds={finishedSavingCardIds}
            />
          ) : (
            <StudyCard
              headline={headline}
              text={text}
              setText={setText}
              isTextFocused={isTextFocused}
              setIsTextFocused={setIsTextFocused}
              handleChange={handleChange}
              card={card}
              numberOfRepetitions={numberOfRepetitions}
              fullView={fullView}
              saveCard={saveCard}
              correctAmount={card.correct}
              deleteCard={deleteCard}
              cardString={cardString}
              savingCardIds={savingCardIds}
              finishedSavingCardIds={finishedSavingCardIds}
            />
          )
        )}
      </div>
    );
    
}

export default Flashcard;
const FullscreenIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
                d="M4 14V20M4 20H10M4 20L9 15"
                stroke="#222222"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20 10V4M20 4H14M20 4L15 9"
                stroke="#222222"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default FullscreenIcon;

import React from 'react';
import styles from './finishcard.module.css';
import Confetti from 'react-confetti'


const FinishCard = ({numberOfRepetitions}) => {

  return (
    <div className={styles.container}>
      <Confetti className={styles.container}></Confetti>
        <div className={styles.content}>
            <div className={styles.card}>
                <div className={styles.placeholder}>
                <div className={styles.headlineLarge}>
                    Congratulations! <br></br><br></br>

                    You have learned all cards of this deck successfully for {numberOfRepetitions} times
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default FinishCard;
import React from 'react';
import styles from './resetbutton.module.css'; 

const ResetButton = ({ isVisible, onClose, onReset }) => {
  if (!isVisible) return null;

  const resetMessage = "Are you sure you want to reset your progress for this card deck?\n This action cannot be undone."
  return (
    <div className={styles.overlay}>
      <div className={styles.window}>
        <header className={styles.header}>
          <span>Reset Progress</span>
          <button onClick={onClose}>&times;</button>
        </header>
        <div className={styles.content}>
        {resetMessage.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <div className={styles.buttonContainer}>
            <button onClick={onReset} className={`${styles.actionButton} ${styles.redButton}`}>Accept</button>
            <button onClick={onClose} className={`${styles.actionButton} ${styles.blueButton}`}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetButton;

import styles from "./App.module.css";

import Toolbar from "./components/Toolbar/Toolbar";

import ChatRoom from "./components/ChatRoom";
import DownloadModal from "./components/DownloadModal";
import FileManagerNew from "./components/FileManagerNew";
import FlashCardButton from "./components/FlashcardButton";
import ReactPDFViewer from "./components/ReactPDFViewer";
import StartLayer from "./components/StartLayer/StartLayer";
import SummarizeButton from "./components/SummaryButton";
import TinyEditor from "./components/TinyEditor";

import PlanInfo from "./components/Planinfo/PlanInfo";

// import module react and hooks "useEffect", "useSate", ...
import React, { useEffect, useRef, useState } from "react";

// handle Request-Response-Stuff with axios
import axios from "axios";

import BarLoader from "react-spinners/BarLoader";
import ClipLoader from "react-spinners/ClipLoader";
import AuthPage from "./components/AuthPage/AuthPage";

import UAParser from "ua-parser-js";

//HANDOVER: why is this in curly-braces?
// this imports the hook "MemberstackProvider"
import { MemberstackProvider, useMemberstackModal } from "@memberstack/react";

import ErrorModal from "./components/ErrorModal";
import FeedbackModal from "./components/FeedbackModal";
import FlashCardContainer from "./components/FlashCardContainer";
import InfoBanner from "./components/InfoBanner";
import jokes from "./components/Jokes";
import RegenerateCard from "./components/RegenerateCard";
import UpgradeCard from "./components/UpgradeCard";

const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const BASE_WSS_ADDRESS = process.env.REACT_APP_API_GATEWAY_WS;

const CLEAR_CHAT_ENDPOINT = BASE_API_ADDRESS + "/delete_chat";
const GET_SUMMARY_ENDPOINT = BASE_API_ADDRESS + "/get_summary";
const GET_SUMMARY_TITLE_ENDPOINT = BASE_API_ADDRESS + "/get_summary_title";
const GET_FLASHCARDS_ENDPOINT = BASE_API_ADDRESS + "/get_flashcards";
const GET_FLASHCARDS_TITLE_ENDPOINT = BASE_API_ADDRESS + "/get_flashcards_title";
const GET_FILEMANAGER_ENDPOINT = BASE_API_ADDRESS + "/get_filemanager";
const UPDATE_FILEMANAGER_ENDPOINT = BASE_API_ADDRESS + "/add_dates_to_filemanager";
const GET_PDF_URL_ENDPOINT = BASE_API_ADDRESS + "/get_pdf_url";
const GET_CHAT_ENDPOINT = BASE_API_ADDRESS + "/get_chat";
const UPLOAD_URL_ENDPOINT = BASE_API_ADDRESS + "/generate_upload_link";
const ADD_TO_DATABASE_ENDPOINT = BASE_API_ADDRESS + "/add_document_to_database";
const ADD_DOCUMENT_TO_USER_ENDPOINT = BASE_API_ADDRESS + "/add_document_to_user";
const WESOCKET_SUMMARY_GENERATION = BASE_WSS_ADDRESS;
const UPLOAD_SUMMARY_AS_JSON_ENDPOINT = BASE_API_ADDRESS + "/generate_upload_link_summary";
const UPLOAD_FILESYSTEM_AS_JSON_ENDPOINT = BASE_API_ADDRESS + "/generate_upload_link_filemanager";
const EXPORT_FLASHCARDS_ENDPOINT = BASE_API_ADDRESS + "/export_to_anki";
const GET_COUNTY_FROM_IP_ENDPOINT = BASE_API_ADDRESS + "/get_country_from_ip_address";
const WAKE_UP_DOCUMENT_PROCESSOR_URL = BASE_API_ADDRESS + "/wake_up_document_processor";
const WAKE_UP_TEXT_EXTRACT_URL = BASE_API_ADDRESS + "/wake_up_text_extractor";
const WAKE_UP_CONVERT_TO_PDF_URL = BASE_API_ADDRESS + "/wake_up_convert_to_pdf";
const UPLOAD_FEEDBACK_AS_JSON_ENDPOINT = BASE_API_ADDRESS + "/generate_upload_link_feedback";
const SUBMIT_FEEDBACK_ENDPOINT = BASE_API_ADDRESS + "/submit_feedback";
const SUBMIT_SUPPORT_REQUEST_ENDPOINT = BASE_API_ADDRESS + "/submit_support_request";
const SET_DEFAULT_LANGUAGE_ENDPOINT = BASE_API_ADDRESS + "/set_default_language";

// used for memberstack
const memberstackConfig = {
    publicKey: "pk_64aafa45890b01d2e1fe",
};

const APP_MODE_TUTOR = "tutor";
const APP_MODE_SUMMARY = "summary";
const APP_MODE_CARDS = "cards";

function Learnboost({
    setUserId,
    user_id,
    setProfilePicture,
    profilePicture,
    setIsAuthenticated,
    isPaidUser,
    plan,
    language,
}) {
    const [isUploading, setisUploading] = useState(false);
    const [hasUploaded, sethasUploaded] = useState(false);
    const [isSummarizing, setisSummarizing] = useState(false);
    const [hasSummarized, sethasSummarized] = useState(false);
    const [isGeneratingFlashCards, setIsGeneratingFlashCards] = useState(false);
    const [isExportingFlashCards, setIsExportingFlashCards] = useState(false);
    const [hasFlashCards, setHasFlashCards] = useState(false);
    const [flashcardStatus, setFlashCardStatus] = useState("complete");
    const [summaryStatus, setSummaryStatus] = useState("complete");
    const [flashcardLogs, setFlashCardLogs] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("German");
    const [appMode, setAppMode] = useState(APP_MODE_TUTOR);
    const [pdfURL, setPDFURL] = useState("");
    const [document_id, setDocumentId] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileNameId, setFileNameId] = useState(1);
    const [chat, setChat] = useState([]);
    const [summary, setSummary] = useState("");
    const [initial_summary, setInitialSummary] = useState(""); // used to track changes for autosave
    const [summaryOnLoad, setSummaryOnLoad] = useState(""); // only changes on document change
    const [summary_title, setSummaryTitle] = useState("");
    const [flashcards, setFlashCards] = useState("");
    const [flashcards_title, setFlashCardsTitle] = useState("");
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [isSavingSummary, setIsSavingSummary] = useState(false);
    const [isShowingCheckIcon, setIsShowingCheckIcon] = useState(false); //shows check Icon after summary is savedf
    const [isStudyModeActive, setIsStudyModeActive] = useState(false);
    const [summaryId, setSummaryId] = useState(1);
    const [numberOfRepetitions, setNumberOfRepetitions] = useState(3);

    const [fileSystem, setFileSytem] = useState("");
    const [initalFileSystem, setInitialFileSystem] = useState("");

    const [currentAreas, setCurrentAreas] = useState([]);
    const [currentPageReference, setCurrentPageReference] = useState(0);

    const [loadingUpload, setLoadingUpload] = useState("Uploading document");
    const [loadingSummaryText, setLoadingSummaryText] = useState("Preparing document for summary");
    const [loadingFlashcardsText, setLoadingFlashcardsText] = useState(
        "Preparing document for generating flashcards"
    );

    const [uploadProgess, setUploadProgress] = useState(0);

    const [isFileManagerVisible, setFileManagerVisible] = useState(false);
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
    const [feedbackMode, setFeedbackMode] = useState("standard");
    const [selectedUploadFile, setSelectedUploadFile] = useState(null);

    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [downloadLink, setDownloadLink] = useState("");

    const [bannerData, setBannerData] = useState({ visible: false, message: "" });

    const showInfoBanner = (message) => {
        setBannerData({ visible: true, message: message });
    };

    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [joke, setJoke] = useState(jokes[0]);

    const toggleResetModal = () => {
        setIsResetModalOpen(!isResetModalOpen);
    };

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

    const [isChangingDocument, setIsChangingDocument] = useState(false);

    // Determined by ISO 3166-1 alpha-2
    const [countryCode, setCountryCode] = useState("DE");

    const [errorInfo, setErrorInfo] = useState({ isVisible: false, message: "", statusCode: "" });

    const showError = (message, statusCode) =>
        setErrorInfo({ isVisible: true, message: message, statusCode: statusCode });
    const hideError = () => setErrorInfo({ isVisible: false, message: "", statusCode: "" });

    const MAX_UPLOAD_SIZE_FREE_IN_BYTES = 25000000;
    const MAX_UPLOAD_SIZE_PAID_IN_BYTES = 75000000;

    const [topComparator, setTopComparator] = useState("date");

    const welcomeMessage =
        "Welcome! I'm thrilled to assist you in Tutor AI mode. Here, you can delve into specific queries about your document, whether it's breaking down complex information or simplifying concepts for better understanding. Should you require detailed summaries, feel free to switch to the Summarize AI mode. Remember, I'm here to guide you using only the information provided in your document unless you allow me to use external information. Let's embark on this learning journey together! <sup>1</sup>";

    const toggleCheckMark = () => {
        setIsShowingCheckIcon(true);
        setTimeout(() => {
            setIsShowingCheckIcon(false);
        }, 3000);
    };

    const getUserDeviceDetails = () => {
        const parser = new UAParser();
        const result = parser.getResult();
        const browserName = result.browser.name;
        const osName = result.os.name;
        const device = parser.getDevice();

        const deviceFormatted = JSON.parse(
            JSON.stringify(device, (key, value) => (value === undefined ? "Desktop" : value))
        );

        return { browser: browserName, os: osName, device: deviceFormatted };
    };

    function convertDateToString(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    // useEffect is executed everytime the dependency-array changes (the second parameter)
    // if the dep-array is empty useEffect will be called once when the page is loaded and never again
    useEffect(() => {
        get_country_from_ip();
        fetchDocuments();
    }, []);

    const fileSystemRef = useRef(fileSystem);

    useEffect(() => {
        fileSystemRef.current = fileSystem;
    }, [fileSystem]);

    const initialFileSystemRef = useRef(initalFileSystem);

    useEffect(() => {
        initialFileSystemRef.current = initalFileSystem;
    }, [initalFileSystem]);

    const isChangingDocumentRef = useRef(isChangingDocument);

    useEffect(() => {
        isChangingDocumentRef.current = isChangingDocument;
    }, [isChangingDocument]);

    const hasSummarizedRef = useRef(hasSummarized);

    useEffect(() => {
        hasSummarizedRef.current = hasSummarized;
    }, [hasSummarized]);

    const summaryRef = useRef(summary);

    useEffect(() => {
        summaryRef.current = summary;
    }, [summary]);

    const initialSummaryRef = useRef(initial_summary);

    useEffect(() => {
        initialSummaryRef.current = initial_summary;
    }, [initial_summary]);

    const documentIdRef = useRef(document_id);

    useEffect(() => {
        documentIdRef.current = document_id;
    }, [document_id]);

    const userIdRef = useRef(user_id);

    useEffect(() => {
        userIdRef.current = user_id;
    }, [user_id]);

    useEffect(() => {
        const interval = setInterval(() => {
            trigger_save_summary();
        }, 30000); // 30 000 milliseconds = 30 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            trigger_save_filesystem();
        }, 5000); // 5000 milliseconds = 5 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const trigger_save_filesystem = async () => {
        const isFilesystemEqual =
            JSON.stringify(fileSystemRef.current) === JSON.stringify(initialFileSystemRef.current);

        if (!isFilesystemEqual) {
            setInitialFileSystem(fileSystemRef.current);
            console.log("saving filemanager");
            await saveFileSystem();
        }
    };

    const saveFileSystem = async () => {
        try {
            const response = await axios.get(UPLOAD_FILESYSTEM_AS_JSON_ENDPOINT, {
                params: {
                    user_id: user_id,
                },
            });
            const presignedUrl = response.data.presigned_url;
            //console.log(presignedUrl)

            const fileSystemJson = JSON.stringify(fileSystemRef.current);
            const file = new Blob([fileSystemJson], { type: "application/json" });

            await uploadFileToS3(presignedUrl, file);
            console.log("successfully saved filemanager");
        } catch (error) {
            console.log(error);
        }
    };

    const trigger_save_summary = async () => {
        console.log("Check for auto save");
        if (hasSummarizedRef.current === false || isChangingDocumentRef === true) {
            return;
        }
        const isSummaryEqual =
            JSON.stringify(summaryRef.current) === JSON.stringify(initialSummaryRef.current);

        if (!isSummaryEqual) {
            setInitialSummary(summaryRef.current);
            setIsSavingSummary(true);
            console.log("saving...");
            await saveSummary(documentIdRef.current);
            setIsSavingSummary(false);
            toggleCheckMark();
        }
    };

    const saveSummary = async (document_id) => {
        try {
            const response = await axios.get(UPLOAD_SUMMARY_AS_JSON_ENDPOINT, {
                params: {
                    user_id: user_id,
                    document_id: document_id,
                },
            });
            const presignedUrl = response.data.presigned_url;
            console.log(presignedUrl);

            const summaryJson = JSON.stringify(summaryRef.current);
            const file = new Blob([summaryJson], { type: "application/json" });

            await uploadFileToS3(presignedUrl, file);

            //const response_summary_endpoint = await axios.get(SAVE_SUMMARY_ENDPOINT, { params: { user_id, document_id } });
            //console.log(response_summary_endpoint.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchDocuments = async (counter = 1) => {
        try {
            if (fileSystem === "") {
                const response = await axios.get(GET_FILEMANAGER_ENDPOINT, { params: { user_id } });
                const filemanagerdownloadurl = response.data;

                const s3Response = await axios.get(filemanagerdownloadurl);
                const newFileSystem = s3Response.data;
                if (!newFileSystem[0].sorting && counter === 1) {
                    await updateFileManager();
                    await fetchDocuments(counter++);
                    return;
                }
                console.log("Filemanager:", newFileSystem);
                setFileSytem(newFileSystem);
                setInitialFileSystem(newFileSystem);
                setTopComparator(newFileSystem[0]?.sorting === "date" ? "name" : "date");
                console.log("Sorting:", newFileSystem[0]?.sorting);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateFileManager = async () => {
        try {
            await axios.get(UPDATE_FILEMANAGER_ENDPOINT, { params: { user_id: user_id } });
        } catch (error) {
            console.log(error);
        }
    };

    const get_pdf_url = async (document_id) => {
        try {
            const response = await axios.get(GET_PDF_URL_ENDPOINT, {
                params: { document_id: document_id },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const submitSupportRequest = async (message, statusCode) => {
        try {
            return await axios.get(SUBMIT_SUPPORT_REQUEST_ENDPOINT, {
                params: {
                    user_id: user_id,
                    document_id: document_id,
                    statusCode: statusCode,
                    message: message,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const toggleFileManager = () => {
        console.log("toggle filemanager");
        fetchDocuments();
        if (isUploading !== true) {
            setFileManagerVisible(!isFileManagerVisible);
        } else {
            showInfoBanner("You can't open the filemanager while uploading a document.");
        }
    };

    const toggleFeedbackModal = () => {
        setIsFeedbackModalVisible(!isFeedbackModalVisible);
    };

    const toggleDownloadModal = () => {
        setIsDownloadModalVisible(!isDownloadModalVisible);
    };

    const toggleUpgradeModal = () => {
        console.log("upgrade modal");
        setIsUpgradeModalOpen(!isUpgradeModalOpen);
    };

    const toggleStudyMode = () => {
        if (isChangingDocument || document_id === "" || hasFlashCards === false) {
            setIsStudyModeActive(false);
            return;
        }
        setIsStudyModeActive(!isStudyModeActive);
    };

    const handleFileSelect = async (document_id, file_name) => {
        console.log("Selected file id:", document_id);
        console.log("Selected file name", file_name);
        setIsChangingDocument(true);
        setIsStudyModeActive(false);
        toggleFileManager();
        await trigger_save_summary();
        setDocumentId(document_id);
        setFileName(file_name);
        setFileNameId(fileNameId + 1);
        const file_url = await get_pdf_url(document_id);
        setPDFURL(file_url);
        sethasUploaded(true);
        await getChat(document_id);
        getSummaryTitle(document_id);
        getFlashCardsTitle(document_id);
        await getFlashCards(document_id);
        await getSummary(document_id);
        setIsChangingDocument(false);
    };

    const clearChat = async () => {
        //Do not do anything if chat is already empty to avoid unnecessary API calls
        if (chat.length === 0) {
            return;
        }
        try {
            //Using document id here to delete
            await axios.get(CLEAR_CHAT_ENDPOINT, { params: { document_id } });
            setChat([]);
            console.log("Chat successfully cleared");
        } catch (error) {
            console.log(error);
        }
    };

    const getSummary = async (document_id) => {
        try {
            const response = await axios.get(GET_SUMMARY_ENDPOINT, {
                params: { document_id: document_id },
            });
            const summaryData = response.data;
            console.log(summaryData);
            if (!summaryData || summaryData === "" || !summaryData.download) {
                sethasSummarized(false);
                setSummary("");
                setInitialSummary("");
                setSummaryOnLoad("");
                return;
            }

            if (summaryData.status === "complete") {
                setSummaryStatus("complete");
            }
            if (summaryData.status === "incomplete") {
                setSummaryStatus("incomplete");
            }
            if (summaryData.status === "exceeded limit") {
                setSummaryStatus("exceeded limit");
            }

            // Use the pre-signed URL to fetch the actual summary data from S3
            const s3Response = await axios.get(summaryData.download);
            const newSummary = s3Response.data; // Assuming the summary data is JSON
            //console.log("Summary",newSummary)
            sethasSummarized(true);
            setSummary(newSummary);
            setInitialSummary(newSummary);
            setSummaryOnLoad(newSummary);
            setSummaryId(summaryId + 1);
        } catch (error) {
            console.log(error);
        }
    };

    const getSummaryTitle = async (document_id) => {
        try {
            const response = await axios.get(GET_SUMMARY_TITLE_ENDPOINT, {
                params: { document_id: document_id },
            });
            const newSummaryTitle = response.data;
            setSummaryTitle(newSummaryTitle);
        } catch (error) {
            console.log(error);
        }
    };

    const getFlashCards = async (document_id) => {
        try {
            const response = await axios.get(GET_FLASHCARDS_ENDPOINT, {
                params: { document_id: document_id },
            });
            const downloadFlashcards = response.data;
            if (downloadFlashcards === "") {
                setHasFlashCards(false);
                setFlashCards("");
                return;
            }

            const s3Response = await axios.get(downloadFlashcards);
            const newFlashcards = s3Response.data;
            setFlashCardStatus(newFlashcards.status);
            setFlashCardLogs(newFlashcards.logs);
            //console.log(newFlashcards)
            //console.log(newFlashcards.logs);
            setFlashCards(newFlashcards.flashcards);
            setHasFlashCards(true);
            //console.log("Flashcards Logs", flashcardLogs);
            console.log("Flashcards Status:", newFlashcards.status);
            console.log("Flashcards", newFlashcards.flashcards);
        } catch (error) {
            console.log(error);
        }
    };

    const getFlashCardsTitle = async (document_id) => {
        try {
            const response = await axios.get(GET_FLASHCARDS_TITLE_ENDPOINT, {
                params: { document_id: document_id },
            });
            const newFlashCardsTitle = response.data;
            setFlashCardsTitle(newFlashCardsTitle);
        } catch (error) {
            console.log(error);
        }
    };

    const getChat = async (document_id) => {
        try {
            const response = await axios.get(GET_CHAT_ENDPOINT, {
                params: { document_id: document_id },
            });
            const newChat = response.data;
            console.log("New Chat", newChat);
            setChat(newChat);
        } catch (error) {
            console.log(error);
        }
    };

    const handleWebSocketRequest = (document_id, actionType, webSocketUrl) => {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(webSocketUrl);
            const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes
            let timeoutId;

            ws.onopen = () => {
                console.log("Websocket connection opened");
                ws.send(
                    JSON.stringify({ action: actionType, message: document_id, user_id: user_id })
                );
                console.log(`Sent ${actionType} request`);
                timeoutId = setTimeout(() => {
                    ws.close();
                    reject(new Error("Timeout waiting for WebSocket response"));
                }, TIMEOUT_DURATION);
            };

            ws.onmessage = (event) => {
                clearTimeout(timeoutId);
                console.log(event);
                const data = JSON.parse(event.data);
                if ("percentage" in data) {
                    setLoadingUpload(data.message);
                    return;
                }
                if ("status_code" in data && data.status_code !== 200) {
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    resolve(null);
                    ws.close();
                }
                console.log(data);
                resolve(data);
                ws.close();
                console.log("Websocket connection closed");
            };

            ws.onerror = (error) => {
                clearTimeout(timeoutId);
                console.error("WebSocket error: ", error);
                reject(error);
            };

            ws.onclose = (event) => {
                clearTimeout(timeoutId);
                if (!event.wasClean) {
                    reject(new Error("WebSocket closed with unexpected error"));
                }
            };
        });
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const generateSummary = async (
        document_id,
        language,
        config,
        pages,
        include_images,
        onSummaryReceived,
        onError
    ) => {
        const ws = new WebSocket(WESOCKET_SUMMARY_GENERATION);
        const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes
        const KEEP_ALIVE_INTERVAL = 10 * 1000; // 5 seconds
        let timeoutId;
        let keepAliveId;

        const sendKeepAlive = () => {
            if (ws.readyState === ws.OPEN) {
                console.log("Sending keep-alive ping");
                ws.send(JSON.stringify({ action: "keepAlive" }));
            }
        };

        ws.onopen = () => {
            console.log("Websocket connection opened");
            ws.send(
                JSON.stringify({
                    action: "summary_v5",
                    message: document_id,
                    user_id: user_id,
                    language: language,
                    config: config,
                    pages: pages,
                    include_images: include_images,
                })
            );
            console.log("Send Summary request");
            timeoutId = setTimeout(() => {
                ws.close();
                onError(new Error("Timeout waiting for WebSocket response"));
            }, TIMEOUT_DURATION);
            keepAliveId = setInterval(sendKeepAlive, KEEP_ALIVE_INTERVAL);
        };

        ws.onmessage = async (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            const data = JSON.parse(event.data);

            // Check if it's a keep-alive response
            if (typeof data === "string") {
                console.log("Keep-alive response received");
                console.log(data);
                await sleep(10000);
                sendKeepAlive();
                return;
            }

            // Check if it is a progress update
            if ("percentage" in data) {
                setLoadingSummaryText(data.message);
                return;
            }

            if ("status_code" in data) {
                // The first condition is an error that is handled separately
                if (data.status_code === 201) {
                    setSummaryStatus("exceeded limit");
                    setisSummarizing(false);
                    ws.close();
                }

                if (data.status_code !== 200 && data.status_code !== 201) {
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    setisSummarizing(false);
                    ws.close();
                }
            }

            console.log(data);
            onSummaryReceived(data);
            setLoadingSummaryText("Preparing document for summary");
            ws.close();
            console.log("Websocket connection closed");
        };

        ws.onerror = (error) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            console.error("WebSocket error: ", error);
            onError(error);
        };

        ws.onclose = (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            if (!event.wasClean) {
                onError(new Error("WebSocket closed with unexpected error"));
            }
        };
    };

    const handleSummaryCreation = (document_id, language, config, pages, include_images) => {
        sethasSummarized(false);
        const randomIndex = Math.floor(Math.random() * jokes.length);
        if (randomIndex >= 0 || randomIndex < jokes.length) {
            setJoke(jokes[randomIndex]);
        }
        setisSummarizing(true);
        generateSummary(
            document_id,
            language,
            config,
            pages,
            include_images,
            async (reply) => {
                // onSummaryReceived callback
                console.log(reply);
                setisSummarizing(false);
                setIsChangingDocument(true);
                await getSummary(document_id);
                setIsChangingDocument(false);
            },
            (error) => {
                // onError callback
                console.log(error);
                setisSummarizing(false);
            }
        );
    };

    const generateFlashcards = async (
        document_id,
        language,
        config,
        pages,
        include_images,
        onFlashcardsReceived,
        onError
    ) => {
        const ws = new WebSocket(WESOCKET_SUMMARY_GENERATION);
        const TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes
        const KEEP_ALIVE_INTERVAL = 10 * 1000; // 5 seconds
        let timeoutId;
        let keepAliveId;

        const sendKeepAlive = () => {
            if (ws.readyState === ws.OPEN) {
                console.log("Sending keep-alive ping");
                ws.send(JSON.stringify({ action: "keepAlive" }));
            }
        };

        ws.onopen = () => {
            console.log("Websocket connection opened");
            ws.send(
                JSON.stringify({
                    action: "flashcards_v6",
                    message: document_id,
                    user_id: user_id,
                    language: language,
                    config: config,
                    pages: pages,
                    include_images: include_images,
                })
            );
            console.log("Send Flashcards request");
            timeoutId = setTimeout(() => {
                ws.close();
                onError(new Error("Timeout waiting for WebSocket response"));
            }, TIMEOUT_DURATION);
            keepAliveId = setInterval(sendKeepAlive, KEEP_ALIVE_INTERVAL);
        };

        ws.onmessage = async (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            const data = JSON.parse(event.data);

            // Check if it's a keep-alive response
            if (typeof data === "string") {
                console.log("Keep-alive response received");
                console.log(data);
                await sleep(10000);
                sendKeepAlive();
                return;
            }

            // Check if it is a progress update
            if ("percentage" in data) {
                setLoadingFlashcardsText(data.message);
                return;
            }

            if ("status_code" in data) {
                // The first error is handled separately
                if (data.status_code === 201) {
                    setFlashCardStatus("exceeded limit");
                    setIsGeneratingFlashCards(false);
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    ws.close();
                }
                if (data.status_code !== 200 && data.status_code !== 201) {
                    setIsGeneratingFlashCards(false);
                    setErrorInfo({
                        isVisible: true,
                        message: data.message,
                        statusCode: data.status_code,
                    });
                    ws.close();
                }
            }

            console.log(data);
            onFlashcardsReceived(data);
            setLoadingFlashcardsText("Preparing document for generating flashcards");
            ws.close();
            console.log("Websocket connection closed");
        };

        ws.onerror = (error) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            console.error("WebSocket error: ", error);
            onError(error);
        };

        ws.onclose = (event) => {
            clearTimeout(timeoutId);
            clearInterval(keepAliveId);
            if (!event.wasClean) {
                onError(new Error("WebSocket closed with unexpected error"));
            }
        };
    };

    const handleFlashcardCreation = (document_id, language, config, pages, includeImages) => {
        setHasFlashCards(false);
        setIsGeneratingFlashCards(true);
        const randomIndex = Math.floor(Math.random() * jokes.length);
        if (randomIndex >= 0 || randomIndex < jokes.length) {
            setJoke(jokes[randomIndex]);
        }
        generateFlashcards(
            document_id,
            language,
            config,
            pages,
            includeImages,
            async (reply) => {
                console.log(reply);
                setIsGeneratingFlashCards(false);
                setIsChangingDocument(true);
                await getFlashCards(document_id);
                setIsChangingDocument(false);
            },
            (error) => {
                console.log(error);
                setIsGeneratingFlashCards(false);
            }
        );
    };

    const convertToPdf = async (document_id) => {
        try {
            const response = await handleWebSocketRequest(
                document_id,
                "convert_to_pdf",
                WESOCKET_SUMMARY_GENERATION
            );
            console.log("Successfully converted file to pdf");
            return response;
        } catch (error) {
            console.log(error);
            setisUploading(false); // Handle error state
            return null;
        }
    };

    const extractText = async (document_id) => {
        try {
            const response = await handleWebSocketRequest(
                document_id,
                "extract_text_v5",
                WESOCKET_SUMMARY_GENERATION
            );
            console.log("Successfully extracted text from pdf");
            return response;
        } catch (error) {
            console.log(error);
            setisUploading(false); // Handle error state
            return null;
        }
    };

    const download_summary_pdf = async () => {
        if (summary === "" || document_id === "" || isChangingDocument === true) {
            return;
        }

        const infoBannerTimeout = setTimeout(() => {
            showInfoBanner(
                "Please remain patient.\nLong Summaries with lots of images can take up to 3 minutes"
            );
        }, 15000); // 15 seconds

        const current_document_id = document_id;
        setIsGeneratingPDF(true);
        await trigger_save_summary();
        try {
            const response = await handleWebSocketRequest(
                current_document_id,
                "render_summary_pdf_v2",
                WESOCKET_SUMMARY_GENERATION
            );
            const download_url = response.message;

            setDownloadLink(download_url);
            toggleDownloadModal();
            clearTimeout(infoBannerTimeout);
        } catch (error) {
            console.log(error);
            clearTimeout(infoBannerTimeout);
        }
        setIsGeneratingPDF(false);
    };

    const download_flashcards = async () => {
        if (flashcards === "" || document_id === "" || isChangingDocument === true) {
            return;
        }

        setIsExportingFlashCards(true);
        try {
            const response = await axios.get(EXPORT_FLASHCARDS_ENDPOINT, {
                params: { document_id: document_id },
            });
            const download_url = response.data;

            setDownloadLink(download_url);
            toggleDownloadModal();
        } catch (error) {
            console.log(error);
        }
        setIsExportingFlashCards(false);
    };

    const processPdf = async (document_id) => {
        try {
            const response = await handleWebSocketRequest(
                document_id,
                "process_document_v5",
                WESOCKET_SUMMARY_GENERATION
            );
            console.log("Successfully processed pdf");
            return response;
        } catch (error) {
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 203,
            });
            console.log(error);
            setisUploading(false); // Handle error state
            return null;
        }
    };

    const addInfoToDatabase = async (user_id, file, file_id, file_url) => {
        try {
            const response = await axios.get(ADD_TO_DATABASE_ENDPOINT, {
                params: {
                    user_id: user_id,
                    document_id: file_id,
                    file_name: file.name,
                    file_url: file_url,
                },
            });
            console.log("Document succesfully added to the database");
            return response;
        } catch (error) {
            console.error("There was an error adding the document to the database", error);
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 204,
            });
            return null;
        }
    };

    const addDocumentToUser = async (user_id, file_id, file_name, file_url) => {
        try {
            const response = await axios.get(ADD_DOCUMENT_TO_USER_ENDPOINT, {
                params: {
                    user_id: user_id,
                    document_id: file_id,
                    file_name: file_name,
                    file_url: file_url,
                },
            });
            console.log("Document succesfully added to the database");
            return response;
        } catch (error) {
            console.error("There was an error adding the document to the user", error);
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 205,
            });
            return null;
        }
    };

    const uploadFileToS3 = async (presignedUrl, file) => {
        try {
            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentage);
                },
            };

            const result = await axios.put(presignedUrl, file, config);
            if (result.status === 200) {
                console.log("Successfully uploaded file to S3");
                return result;
            } else {
                console.error("Error uploading file to S3", result);
                setErrorInfo({
                    isVisible: true,
                    message:
                        "There was an error uploading the file. Try again or contact our support if the issue persists.",
                    statusCode: 206,
                });
                return null;
            }
        } catch (error) {
            console.error("There was an error uploading the file to S3", error);
            setErrorInfo({
                isVisible: true,
                message:
                    "There was an error uploading the file. Try again or contact our support if the issue persists.",
                statusCode: 206,
            });
            return null;
        }
    };

    const handleFileChange = async (e) => {
        setFileManagerVisible(false);
        const file = e.target.files[0];
        if (file) {
            trigger_save_summary();
            sethasUploaded(false);
            setSelectedUploadFile(file);
            setisUploading(true);
            console.log("File selected:", file);
            if (isPaidUser === false && file.size > MAX_UPLOAD_SIZE_FREE_IN_BYTES) {
                showError(
                    `Free users are allowed to upload files up to a size of ${
                        MAX_UPLOAD_SIZE_FREE_IN_BYTES / 1000000
                    }mb. Please upgrade to upload larger files.`,
                    207
                );
                setisUploading(false);
                return null;
            }
            if (isPaidUser === true && file.size > MAX_UPLOAD_SIZE_PAID_IN_BYTES) {
                showError(
                    `Users are allowed to upload files up to a size of ${
                        MAX_UPLOAD_SIZE_PAID_IN_BYTES / 1000000
                    }mb.`,
                    207
                );
                setisUploading(false);
                return null;
            }
            const AcceptedFileTypes = [
                ".pdf",
                ".docx",
                ".DOCX",
                ".doc",
                ".DOC",
                ".pptx",
                ".PPTX",
                ".ppt",
                ".PPT",
                ".odt",
                ".ODT",
            ];
            if (!AcceptedFileTypes.some((type) => file.name.endsWith(type))) {
                showError(
                    "Unsupported file type. You can upload the following file types: .pdf, .docx, .DOCX, .doc, .DOC, .pptx, .PPTX, .ppt, .PPT, .odt, .ODT",
                    208
                );
                setisUploading(false);
                return null;
            }
            // Call API to get presigned URL
            try {
                const response = await axios.get(UPLOAD_URL_ENDPOINT, {
                    params: {
                        user_id: user_id,
                        file_name: file.name,
                    },
                });
                const presignedUrl = response.data.presigned_url;
                const file_url = response.data.file_url;
                console.log(presignedUrl);
                const file_id = response.data.file_id;
                setDocumentId(file_id);
                console.log(file_id);
                const fileTypesToConvert = [
                    ".docx",
                    ".DOCX",
                    ".doc",
                    ".DOC",
                    ".pptx",
                    ".PPTX",
                    ".ppt",
                    ".PPT",
                    ".odt",
                    ".ODT",
                ];
                // Upload file to S3 using the presigned URL
                const responseS3 = await uploadFileToS3(presignedUrl, file);
                if (responseS3 === null) {
                    setisUploading(false);
                    return null;
                }
                setUploadProgress(0);
                setLoadingUpload("Extracting data...");
                const responseAddInfoToDb = await addInfoToDatabase(
                    user_id,
                    file,
                    file_id,
                    file_url
                );
                if (responseAddInfoToDb === null) {
                    setisUploading(false);
                    return null;
                }
                if (fileTypesToConvert.some((type) => file.name.endsWith(type))) {
                    setLoadingUpload("Converting file..");
                    const responseConvertToPdf = await convertToPdf(file_id);
                    if (responseConvertToPdf === null) {
                        setisUploading(false);
                        return null;
                    }
                }
                const reponseExtractText = await extractText(file_id);
                if (reponseExtractText === null) {
                    setisUploading(false);
                    return null;
                }
                const responseProcessPdf = await processPdf(file_id);
                if (responseProcessPdf === null) {
                    setisUploading(false);
                    return null;
                }
                const responseAddDocumentToUser = await addDocumentToUser(
                    user_id,
                    file_id,
                    file.name,
                    file_url
                );
                if (responseAddDocumentToUser === null) {
                    setisUploading(false);
                    return null;
                }
                const pdfURL = await get_pdf_url(file_id);
                setFileName(file.name);
                setFileNameId(fileNameId + 1);
                sethasUploaded(true);
                setPDFURL(pdfURL);
                setSelectedUploadFile(null);
                setChat([{ text: welcomeMessage, uid: -1 }]);
                sethasSummarized(false);
                setSummary("");
                setSummaryTitle("");
                setHasFlashCards(false);
                setFlashCards("");
                setFlashCardsTitle("");
                setFlashCardLogs([]);
                setLoadingUpload("Uploading document");
                return file_id;
            } catch (error) {
                console.error(
                    "There was an error fetching the presigned URL or uploading the file",
                    error
                );
                setErrorInfo({
                    isVisible: true,
                    message:
                        "There was an error uploading the file. Try again or contact our support if the issue persists.",
                    statusCode: 206,
                });
                setSelectedUploadFile(null);
                return null;
            }
        }
    };

    const handleUploadButtonClick = (directory_id = "home") => {
        wake_up_text_extract();
        wake_up_document_processor();
        wake_up_convert_to_pdf();
        document.getElementById(directory_id).click();
    };

    function addNode(parentId, newNode, fileSystem) {
        // This is a recursive function to search for the parent node and add the new node to its children
        function addNodeRecursive(nodes) {
            return nodes.map((node) => {
                if (node.id === parentId) {
                    if (node.children) {
                        return { ...node, children: [...node.children, newNode] };
                    }
                } else if (node.children) {
                    return { ...node, children: addNodeRecursive(node.children) };
                }
                return node;
            });
        }

        return addNodeRecursive(fileSystem);
    }

    const handleUploadDefault = async (e) => {
        wake_up_convert_to_pdf();
        wake_up_text_extract();
        wake_up_document_processor();
        const parentId = "subworkspace-1-1";
        const document_id = await handleFileChange(e);
        if (document_id === null) {
            console.error("There was an error uploading the document.");
            setisUploading(false);
            return;
        }
        const file = e.target.files[0];
        const date = new Date();
        const uploadedFile = {
            id: document_id,
            name: file.name,
            parentId: parentId,
            date: convertDateToString(date),
            type: "file",
            open: false,
            children: [],
        };
        setFileSytem(addNode(parentId, uploadedFile, fileSystem));
        await trigger_save_filesystem();
        setisUploading(false);
    };

    const { openModal, hideModal } = useMemberstackModal();

    const OpenProfileModal = async () => {
        openModal({ type: "PROFILE", planId: "pln_xxxxxxx" })
            .then(({ data, type }) => {
                if (type === "LOGOUT") {
                    handleLogout();
                    console.log("Member logged out");
                }

                hideModal();
            })
            .catch((error) => {
                console.error("Error in Memberstack modal:", error);
            });
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        setUserId(null);
        setProfilePicture(null);
    };

    const get_country_from_ip = async () => {
        try {
            const response = await axios.get(GET_COUNTY_FROM_IP_ENDPOINT, {
                params: { user_id: user_id },
            });
            const data = response.data;
            const countryCode = data.countryCode;
            console.log(countryCode);
            if (countryCode === "Unknown") {
                return;
            }
            setCountryCode(countryCode);
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const wake_up_text_extract = async () => {
        try {
            console.log("waking up text extract");
            await axios.get(WAKE_UP_TEXT_EXTRACT_URL, { params: { user_id: user_id } });
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const wake_up_document_processor = async () => {
        try {
            console.log("waking up document processor");
            await axios.get(WAKE_UP_DOCUMENT_PROCESSOR_URL, { params: { user_id: user_id } });
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const handleLanguageChange = async (language) => {
        setSelectedLanguage(language);
        try {
            //Using document id here to delete
            console.log("changing user's default language to " + language);
            const response = await axios.get(SET_DEFAULT_LANGUAGE_ENDPOINT, {
                params: { user_id, language },
            });
            console.log(response.data);
            console.log("Language successfully updated");
        } catch (error) {
            console.log(error);
        }
    };

    const wake_up_convert_to_pdf = async () => {
        try {
            console.log("waking up convert to pdf");
            await axios.get(WAKE_UP_CONVERT_TO_PDF_URL, { params: { user_id: user_id } });
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const submitFeedback = async (text, stars) => {
        try {
            const response = await axios.get(UPLOAD_FEEDBACK_AS_JSON_ENDPOINT, {
                params: {
                    stars: stars,
                },
            });
            const presignedUrl = response.data.presigned_url;
            const feedback_id = response.data.feedback_id;
            //console.log(presignedUrl)
            //console.log("feedback_id:", feedback_id);

            let browser = "unknown browser";
            let os = "unknown os";
            let device = { vendor: "unknown vendor", model: "unknown model", type: "unknown type" };

            try {
                const userDeviceDetails = getUserDeviceDetails();
                browser = userDeviceDetails.browser;
                os = userDeviceDetails.os;
                device = userDeviceDetails.device;
            } catch {
                console.log("User device details could not be determined");
            }

            const feedback = {
                text: text,
                stars: stars,
                user_id: user_id,
                document_id: document_id,
                feedback_mode: feedbackMode,
                browser: browser,
                os: os,
                device: device,
            };

            const feedbackJson = JSON.stringify(feedback);
            const file = new Blob([feedbackJson], { type: "application/json" });

            //console.log(feedbackJson)

            await uploadFileToS3(presignedUrl, file);
            await axios.get(SUBMIT_FEEDBACK_ENDPOINT, { params: { feedback_id, stars } });
            //console.log(submitted_feedback.data);
        } catch (error) {
            console.log(error);
        }
    };

    /*

  <Toolbar
       user_id={user_id}
       document_id={document_id}
       setAppMode={setAppMode}
       isStudyModeActive={isStudyModeActive}
       toggleStudyMode={toggleStudyMode}
       onDelete={deleteChat}
       onFileManagerToggle={toggleFileManager}
       fileNameId={fileNameId} fileName={fileName}
       setFileName={setFileName}
       handleFileChange={handleFileChange}
       handleUploadButtonClick={handleUploadButtonClick}
       download_summary_pdf={download_summary_pdf}
       isGeneratingPDF={isGeneratingPDF}
       selectedUploadFile={selectedUploadFile}
       openLoginModal={OpenProfileModal}
       profilePicture={profilePicture}
       isPaidUser={isPaidUser}
       plan={plan}
       selectedLanguage={selectedLanguage}
       setSelectedLanguage={setSelectedLanguage}
       isUpgradeModalOpen={isUpgradeModalOpen}
       toggleUpgradeModal={toggleUpgradeModal}
       triggerSaveSummary={trigger_save_summary}
       isSavingSummary={isSavingSummary}
       isShowingCheckIcon={isShowingCheckIcon}
       isExportingFlashCards={isExportingFlashCards}
       download_flashcards={download_flashcards}
       toggleResetModal={toggleResetModal}
       numberOfRepetitions={numberOfRepetitions}
       setNumberOfRepetitions={setNumberOfRepetitions}
       updateNameInFileSystem={updateNameInFileSystem}
       isUploading={isUploading}
       countryCode={countryCode}
       onFeedbackModalToggle={toggleFeedbackModal}
  />


   */

    return (
        <div className={styles["app-container"]}>
            <Toolbar
                currentAppMode={appMode}
                onAppModeChange={setAppMode}
                onToggleFileManager={toggleFileManager}
                onFeedbackButtonClick={toggleFeedbackModal}
                onUpgrade={toggleUpgradeModal}
                onProfileClick={OpenProfileModal}
                onClearChat={clearChat}
                isPaidUser={isPaidUser}
                currentLanguage={selectedLanguage}
                onLanguageChange={handleLanguageChange}
                numberOfRepetitions={numberOfRepetitions}
                setNumberOfRepetitions={setNumberOfRepetitions}
                isExportingFlashCards={isExportingFlashCards}
                download_flashcards={download_flashcards}
                toggleResetModal={toggleResetModal}
                download_summary_pdf={download_summary_pdf}
                onSaveSummary={trigger_save_summary}
                isGeneratingPDF={isGeneratingPDF}
                isSavingSummary={isSavingSummary}
                isStudyModeActive={isStudyModeActive}
                toggleStudyMode={toggleStudyMode}
                isShowingCheckIcon={isShowingCheckIcon}
            />

            {bannerData.visible && (
                <InfoBanner
                    message={bannerData.message}
                    visible={bannerData.visible}
                    setBannerData={setBannerData}></InfoBanner>
            )}
            {
                <ErrorModal
                    isVisible={errorInfo.isVisible}
                    message={errorInfo.message}
                    onClose={hideError}
                    statusCode={errorInfo.statusCode}
                    submitSupportRequest={submitSupportRequest}></ErrorModal>
            }

            {isFileManagerVisible && (
                <FileManagerNew
                    fileSystem={fileSystem}
                    setFileSytem={setFileSytem}
                    isVisible={isFileManagerVisible}
                    onClose={() => setFileManagerVisible(false)}
                    onFileSelect={handleFileSelect}
                    handleUploadButtonClick={handleUploadButtonClick}
                    handleFileChange={handleFileChange}
                    handleUploadDefault={handleUploadDefault}
                    trigger_save_filesystem={trigger_save_filesystem}
                    saveFilesystem={saveFileSystem}
                    setIsUploading={setisUploading}
                    topComparator={topComparator}
                    setTopComparator={setTopComparator}
                />
            )}

            {isFeedbackModalVisible && (
                <FeedbackModal
                    isVisible={isFeedbackModalVisible}
                    onClose={() => setIsFeedbackModalVisible(false)}
                    submitFeedback={submitFeedback}
                />
            )}

            <PlanInfo
                isUpgradeModalOpen={isUpgradeModalOpen}
                toggleUpgradeModal={toggleUpgradeModal}
                countryCode={countryCode}
            />

            {isDownloadModalVisible && (
                <DownloadModal
                    isVisible={isDownloadModalVisible}
                    onClose={toggleDownloadModal}
                    downloadLink={downloadLink}
                />
            )}

            {hasUploaded === false && isUploading === false && (
                <div className={styles["uploadContainer"]}>
                    <StartLayer
                        handleUploadButtonClick={handleUploadButtonClick}
                        selectedUploadFile={selectedUploadFile}
                        handleUploadDefault={handleUploadDefault}
                        onToggleFileManager={toggleFileManager}
                    />
                </div>
            )}

            {hasUploaded === false && isUploading === true && (
                <div className={styles["loadingAnimationUpload"]}>
                    <ClipLoader color="#256EFF" />
                    {uploadProgess > 0 && uploadProgess < 100 ? (
                        <p>Uploading document</p>
                    ) : (
                        <div className={styles["loadingAnimationUploadParagraphContainer"]}>
                            {loadingUpload.split("\n").map((paragraph, index) => (
                                <p className={styles["paragraphUpload"]} key={index}>
                                    {paragraph}
                                </p>
                            ))}
                        </div>
                    )}
                    {uploadProgess > 0 && uploadProgess < 100 && (
                        <progress
                            className={styles["progressbar"]}
                            value={uploadProgess}
                            max="100"
                        />
                    )}
                </div>
            )}

            {hasUploaded === true && !(appMode === APP_MODE_CARDS && isStudyModeActive) && (
                <div className={styles["content-container"]}>
                    <div className={styles["pdf-container"]}>
                        <ReactPDFViewer
                            pdfUrl={pdfURL}
                            currentAreas={currentAreas}
                            currentPageReference={currentPageReference}
                        />
                    </div>
                    {appMode === APP_MODE_TUTOR && (
                        <div className={styles["chat-container"]}>
                            <ChatRoom
                                user_id={user_id}
                                document_id={document_id}
                                chat={chat}
                                setChat={setChat}
                                profilePicture={profilePicture}
                                selectedLanguage={selectedLanguage}
                                toggleUpgradeModal={toggleUpgradeModal}
                                wake_up_document_processor={wake_up_document_processor}
                                setCurrentAreas={setCurrentAreas}
                                setCurrentPageReference={setCurrentPageReference}
                                showInfoBanner={showInfoBanner}
                            />
                        </div>
                    )}

                    {appMode === APP_MODE_SUMMARY && isChangingDocument && (
                        <div className={styles["loadingAnimationSummary"]}>
                            <ClipLoader color="#256EFF" />
                            <div className={styles["text"]}>
                                <p>Loading Summary</p>
                            </div>
                        </div>
                    )}

                    {appMode === APP_MODE_SUMMARY &&
                        !hasSummarized &&
                        !isSummarizing &&
                        !isChangingDocument &&
                        !(summaryStatus === "exceeded limit") && (
                            <div className={styles["summarize-button-container"]}>
                                <SummarizeButton
                                    document_id={document_id}
                                    handleSummaryCreation={handleSummaryCreation}
                                    appLanguage={selectedLanguage}
                                    showInfoBanner={showInfoBanner}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_SUMMARY &&
                        !hasSummarized &&
                        isSummarizing &&
                        !isChangingDocument && (
                            <div className={styles["loadingAnimationSummary"]}>
                                <BarLoader color="#256EFF" />
                                <div className={styles["text"]}>
                                    <p>
                                        <strong>{loadingSummaryText}</strong>
                                    </p>
                                </div>
                                <br></br>
                                <br></br>
                                <div className={styles["joke"]}>
                                    <p>
                                        <i>Random joke:</i>
                                    </p>
                                    <p>{joke.setup}</p>
                                    <p>{joke.punchline}</p>
                                </div>
                            </div>
                        )}

                    {appMode === APP_MODE_SUMMARY && hasSummarized && !isChangingDocument && (
                        <div className={styles["simple-text-editor-container"]}>
                            <TinyEditor
                                key={summaryId}
                                document_id={document_id}
                                summary={summaryOnLoad}
                                setSummary={setSummary}
                                summary_title={summary_title}
                                setSummaryTitle={setSummaryTitle}
                                toggleUpgradeModal={toggleUpgradeModal}
                                triggerSaveSummary={trigger_save_summary}
                                summaryStatus={summaryStatus}
                                isPaidUser={isPaidUser}
                                handleSummaryCreation={handleSummaryCreation}
                                setCurrentAreas={setCurrentAreas}
                                setCurrentPageReference={setCurrentPageReference}
                                toggleFeedbackModal={toggleFeedbackModal}
                                setFeedbackMode={setFeedbackMode}
                            />
                        </div>
                    )}

                    {appMode === APP_MODE_SUMMARY &&
                        !hasSummarized &&
                        !isChangingDocument &&
                        !isSummarizing &&
                        summaryStatus === "exceeded limit" && (
                            <div className={styles["summarize-button-container"]}>
                                <UpgradeCard
                                    firstText={"Those were your first 5 summaries"}
                                    secondText={"Upgrade now to generate unlimited full summaries"}
                                    toggleUpgradeModal={toggleUpgradeModal}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS && isChangingDocument && (
                        <div className={styles["loadingAnimationSummary"]}>
                            <ClipLoader color="#256EFF" />
                            <div className={styles["text"]}>
                                <p>Loading Flashcards</p>
                            </div>
                        </div>
                    )}

                    {appMode === APP_MODE_CARDS &&
                        !hasFlashCards &&
                        isGeneratingFlashCards &&
                        !isChangingDocument && (
                            <div className={styles["loadingAnimationSummary"]}>
                                <BarLoader color="#256EFF" />
                                <div className={styles["text"]}>
                                    <p>
                                        <strong>{loadingFlashcardsText}</strong>
                                    </p>
                                </div>
                                <br></br>
                                <br></br>
                                <div className={styles["joke"]}>
                                    <p>
                                        <i>Random joke:</i>
                                    </p>
                                    <p>{joke.setup}</p>
                                    <p>{joke.punchline}</p>
                                </div>
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS &&
                        !hasFlashCards &&
                        !isGeneratingFlashCards &&
                        !isChangingDocument && (
                            <div className={styles["flashcard-button-container"]}>
                                <FlashCardButton
                                    document_id={document_id}
                                    handleFlashcardCreation={handleFlashcardCreation}
                                    appLanguage={selectedLanguage}
                                    showInfoBanner={showInfoBanner}></FlashCardButton>
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS &&
                        hasFlashCards &&
                        !isChangingDocument &&
                        flashcardStatus === "exceeded limit" &&
                        !isPaidUser && (
                            <div className={styles["flashcard-button-container"]}>
                                <UpgradeCard
                                    firstText={"Those were your first 3 sets of flashcards"}
                                    secondText={
                                        "Upgrade now to generate flashcards for the full document"
                                    }
                                    toggleUpgradeModal={toggleUpgradeModal}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS &&
                        hasFlashCards &&
                        !isChangingDocument &&
                        flashcardStatus === "exceeded limit" &&
                        isPaidUser && (
                            <div className={styles["flashcard-button-container"]}>
                                <RegenerateCard
                                    document_id={document_id}
                                    handleFlashcardCreation={handleFlashcardCreation}
                                />
                            </div>
                        )}

                    {appMode === APP_MODE_CARDS &&
                        hasFlashCards &&
                        !isChangingDocument &&
                        flashcardStatus !== "exceeded limit" && (
                            <div className={styles["flashcard-container"]}>
                                <FlashCardContainer
                                    isStudyModeActive={isStudyModeActive}
                                    document_id={document_id}
                                    user_id={user_id}
                                    uploadFileToS3={uploadFileToS3}
                                    flashcards_title={flashcards_title}
                                    setFlashCardsTitle={setFlashCardsTitle}
                                    flashcards={flashcards}
                                    setFlashCards={setFlashCards}
                                    flashcardStatus={flashcardStatus}
                                    flashcardLogs={flashcardLogs}
                                    isPaidUser={isPaidUser}
                                    toggleUpgradeModal={toggleUpgradeModal}
                                    handleFlashcardCreation={
                                        handleFlashcardCreation
                                    }></FlashCardContainer>
                            </div>
                        )}
                </div>
            )}

            {hasUploaded === true &&
                appMode === APP_MODE_CARDS &&
                isStudyModeActive &&
                !isChangingDocument && (
                    <FlashCardContainer
                        isStudyModeActive={isStudyModeActive}
                        numberOfRepetitions={numberOfRepetitions}
                        flashcardLogs={flashcardLogs}
                        setFlashCardLogs={setFlashCardLogs}
                        isResetModalOpen={isResetModalOpen}
                        toggleResetModal={toggleResetModal}
                        document_id={document_id}
                        user_id={user_id}
                        uploadFileToS3={uploadFileToS3}
                        flashcards_title={flashcards_title}
                        setFlashCardsTitle={setFlashCardsTitle}
                        flashcards={flashcards}
                        setFlashCards={setFlashCards}
                        flashcardStatus={flashcardStatus}
                        isPaidUser={isPaidUser}
                        toggleUpgradeModal={toggleUpgradeModal}
                        handleFlashcardCreation={handleFlashcardCreation}></FlashCardContainer>
                )}

            {hasUploaded === true && !(appMode === APP_MODE_CARDS && isStudyModeActive) && (
                <div className={styles["bottom-text"]}>
                    <div className={styles["left-half"]}></div>
                    <div className={styles["right-half"]}>
                        The Learnboost AI may produce inaccurate information about people, places,
                        or facts.
                    </div>
                </div>
            )}
        </div>
    );
}

function updateViewportMetaTag() {
    const viewportWidth = window.screen.width;
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    if (viewportWidth <= 500) {
        viewportMetaTag.setAttribute("content", "width=device-width, initial-scale=0.2");
        return;
    }
    if (viewportWidth <= 1400 && viewportWidth >= 500) {
        viewportMetaTag.setAttribute("content", "width=device-width, initial-scale=0.5");
        return;
    }
    viewportMetaTag.setAttribute("content", "width=device-width, initial-scale=1");
}

// this is the "main" entrypoint
function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user_id, setUserId] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [isPaidUser, setIsPaidUser] = useState(false);
    const [plan, setPlan] = useState("FREE");
    const [language, setLanguage] = useState("German");

    const handleAuthSuccess = () => {
        setIsAuthenticated(true);
    };

    // this is the "mobile-optimization" to hard-set the view-sizes
    // for mobile-phones and tables
    // executed with a timeout because it's somehow not working otherwise
    setTimeout(updateViewportMetaTag, 1000);

    return (
        <MemberstackProvider config={memberstackConfig}>
            {isAuthenticated ? (
                <Learnboost
                    user_id={user_id}
                    profilePicture={profilePicture}
                    setUserId={setUserId}
                    setProfilePicture={setProfilePicture}
                    setIsAuthenticated={setIsAuthenticated}
                    isPaidUser={isPaidUser}
                    plan={plan}
                    language={language}
                />
            ) : (
                <AuthPage
                    onAuthSuccess={handleAuthSuccess}
                    setUserId={setUserId}
                    setProfilePicture={setProfilePicture}
                    setIsPaidUser={setIsPaidUser}
                    setPlan={setPlan}
                    language={language}
                    setLanguage={setLanguage}
                />
            )}
        </MemberstackProvider>
    );
}

export default App;

const ZoomInIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <circle cx="11" cy="11" r="6" stroke="#222222" />
            <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
            <path d="M11 9V13" stroke="#33363F" stroke-linecap="round" />
            <path d="M13 11L9 11" stroke="#33363F" stroke-linecap="round" />
        </svg>
    );
};

export default ZoomInIcon;

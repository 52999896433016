import React from 'react';
import styles from './regeneratecardeditor.module.css';

const RegenerateCardEditor = ({document_id, handleSummaryCreation}) => {
    return (
        <div className={styles.uploadContainer}>
            <div className={styles.uploadTextSubtitle}>
                Congratulations for upgrading to a premium plan!
                <br></br>
                <br></br>
                Choose the language of your unlimited summary
            </div>
            <div className={styles.buttons}>
              <button className={styles.uploadBtn} onClick={() => handleSummaryCreation(document_id, "English", null, null, true)}>
                English
              </button>
              <button className={styles.uploadBtn} onClick={() => handleSummaryCreation(document_id, "German", null, null, true)}>
                German
              </button>
            </div>
        </div>
      );
}

export default RegenerateCardEditor;
import axios from "axios";
import "katex/dist/katex.min.css";
import React, { useEffect, useRef, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkMath from "remark-math";
import styles from "./chatroom.module.css";

import ReactMarkdown from "react-markdown";

const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const BASE_WSS_ADDRESS = process.env.REACT_APP_API_GATEWAY_WS;

const WAKE_UP_TUTOR_URL = BASE_API_ADDRESS + "/wake_up_tutor";

const wake_up_tutor = async () => {
    try {
        console.log("waking up tutor");
        await axios.get(WAKE_UP_TUTOR_URL, { params: { user_id: "wake_up" } });
    } catch (error) {
        console.log(error);
        return null;
    }
};

const high_traffic_message =
    "Please be patient for a moment, we have recently made technical adjustments to improve the quality of Learnboost. Therefore, we need to re-analyze your document and set it up again for the Tutor AI. This only needs to be done once. You will automatically receive the answer to your last question in a few seconds here in the chat, no additional action is required from your side.";

const sparklesIcon = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
    <path fill="#508BFF" fillRule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clipRule="evenodd" />
  </svg>`
);
const svgDataUrl = `data:image/svg+xml,${sparklesIcon}`;

const sendIcon = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path fill="none" stroke="#FFFFFF" strokeWidth="2" fill-rule="evenodd" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
</svg>`
);

const sendIconURL = `data:image/svg+xml,${sendIcon}`;

const userIcon = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
  <path fill="#C3CAD3" fillRule="evenodd" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" clipRule="evenodd" />
</svg>`
);
const userIconUrl = `data:image/svg+xml,${userIcon}`;

const ClipBoardIcon = ({ handleClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#494949"
        className={styles.clipBoardIcon}
        onClick={() => {
            handleClick();
        }}>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
        />
    </svg>
);

const CheckIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#494949"
        className={styles.clipBoardIcon}>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);

const handleWebSocketRequest = (
    document_id,
    user_id,
    query,
    selectedLanguage,
    onMessageRecieved,
    onError
) => {
    const ws = new WebSocket(BASE_WSS_ADDRESS);
    const TIMEOUT_DURATION = 3 * 60 * 1000; // 1 minute
    let timeoutId;

    ws.onopen = () => {
        console.log("Websocket connection opened");
        ws.send(
            JSON.stringify({
                action: "tutorai_v5",
                document_id: document_id,
                user_id: user_id,
                query: query,
                language: selectedLanguage,
            })
        );
        console.log(`Sent Tutor Ai request`);
        timeoutId = setTimeout(() => {
            ws.close();
            onError(new Error("Timeout waiting for WebSocket response"));
        }, TIMEOUT_DURATION);
    };

    ws.onmessage = (event) => {
        clearTimeout(timeoutId);
        const data = JSON.parse(event.data);
        onMessageRecieved(data);
    };

    ws.onerror = (error) => {
        clearTimeout(timeoutId);
        console.error("WebSocket error: ", error);
        onError(error);
    };

    ws.onclose = (event) => {
        console.log("Websocket Connection closed");
        clearTimeout(timeoutId);
        if (!event.wasClean) {
            onError(new Error("WebSocket closed with unexpected error"));
        }
    };

    return ws;
};

function ChatRoom({
    document_id,
    user_id,
    chat,
    setChat,
    profilePicture,
    selectedLanguage,
    toggleUpgradeModal,
    wake_up_document_processor,
    setCurrentAreas,
    setCurrentPageReference,
    showInfoBanner,
}) {
    const dummy = useRef();
    const textareaRef = useRef(null);
    const formRef = useRef(null);
    const [formHeight, setFormHeight] = useState(0);

    const [formValue, setFormValue] = useState("");
    const [formKey, setFormKey] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    /*useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, [formKey]);*/

    useEffect(() => {
        if (formRef.current) {
            setFormHeight(formRef.current.offsetHeight);
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent the default action of inserting a new line
            sendMessage(e); // Call the sendMessage function
        }
    };

    const generate_answer = (query, user_id, file_id, selectedLanguage) => {
        let isNewMessage = true;
        setIsLoading(true);
        setChat((prevMessages) => [...prevMessages, { text: "", uid: -1 }]);
        const ws = handleWebSocketRequest(
            document_id,
            user_id,
            query,
            selectedLanguage,
            (reply) => {
                // On message received callback
                if (typeof reply === "string" && reply === high_traffic_message) {
                    setChat((prevMessages) => {
                        return [...prevMessages, { text: reply + " ", uid: -1 }];
                    });
                    return;
                }
                setIsLoading(false);
                if (typeof reply === "string") {
                    setChat((prevMessages) => {
                        if (isNewMessage) {
                            isNewMessage = false; // Subsequent messages will update this message
                            return [...prevMessages, { text: reply, uid: -1 }];
                        } else {
                            // Append to the last message
                            const lastMessage = prevMessages[prevMessages.length - 1];
                            return [
                                ...prevMessages.slice(0, -1),
                                { ...lastMessage, text: lastMessage.text + reply },
                            ];
                        }
                    });
                }
                if (reply.statusCode && reply.statusCode === 200) {
                    ws.close(); // Close the WebSocket connection on receiving end signal
                    setTimeout(() => {
                        isNewMessage = true;
                    }, 100); // Reset for the next message
                }
            },
            (error) => {
                // onError callback
                console.log(error);
                setIsLoading(false);
                ws.close(); // Ensure WebSocket is closed on error
            }
        );

        return ws; // Return the WebSocket object for potential later use
    };

    const handleChange = (e) => {
        const textarea = e.target;
        const singleLineHeight = 24; // Adjust this based on the line-height of your textarea
        const maxHeight = 62; // The max height for the textarea

        // Remove any previously set height to get the actual scrollHeight
        textarea.style.height = "auto";

        // Determine if the content is more than one line by comparing scrollHeight to the line height
        const isMultiLine = textarea.scrollHeight > singleLineHeight;

        // If content is more than one line, adjust the height and padding as necessary
        if (isMultiLine) {
            textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
            textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "auto" : "hidden";
            // Adjust the padding here if you want different padding for multiple lines
        } else {
            // Set height to auto and reset any scrolling
            textarea.style.height = "auto";
            textarea.style.overflowY = "hidden";
            // Reset the padding to vertically center the text for a single line
            textarea.style.paddingTop = "0px";
            textarea.style.paddingBottom = "0px";
        }

        // Set the value of formValue state
        setFormValue(textarea.value);
    };

    const sendMessage = (e) => {
        e.preventDefault();
        if (formValue.trim() === "") {
            return;
        }

        const uid = user_id;

        setChat((prevMessages) => [...prevMessages, { text: formValue, uid }]);
        setFormValue("");
        setFormKey((prevKey) => prevKey + 1);

        generate_answer(formValue, user_id, document_id, selectedLanguage);
    };

    useEffect(() => {
        // Assuming `styles.main` is the class for your main chat container
        const chatContainer = document.querySelector(`.${styles.main}`);

        if (chatContainer) {
            // Wait for the browser to finish any pending layout and rendering work
            setTimeout(() => {
                // Scroll to the bottom of the container
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 0); // A timeout of 0 can sometimes be enough to defer the action until after render
        }
    }, [chat.length]); // Depend on chat.length to trigger this effect when new messages are added

    const LoaderMessage = () => (
        <div className={`${styles.message} ${styles.received}`}>
            <img
                className={`${styles.img} ${styles.receivedImg}`}
                src={svgDataUrl}
                alt="assistant avatar"
            />
            <div className={styles.loaderBubble}>
                <BeatLoader size={8} color={"#123abc"} loading={true} />
            </div>
        </div>
    );

    return (
        <>
            <main className={styles.main}>
                {chat &&
                    chat.map((msg) => (
                        <ChatMessage
                            key={msg.id}
                            message={msg}
                            profilePicture={profilePicture === null ? userIconUrl : profilePicture}
                            toggleUpgradeModal={toggleUpgradeModal}
                            setCurrentAreas={setCurrentAreas}
                            setCurrentPageReference={setCurrentPageReference}
                            showInfoBanner={showInfoBanner}
                        />
                    ))}

                {isLoading && <LoaderMessage />}

                <span ref={dummy}></span>
            </main>

            <form ref={formRef} key={formKey} className={styles.form} onSubmit={sendMessage}>
                <textarea
                    ref={textareaRef}
                    className={styles.input}
                    value={formValue}
                    onFocus={() => {
                        wake_up_tutor();
                        wake_up_document_processor();
                    }}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Ask a question"
                    rows="1" // Start with a single line.
                ></textarea>

                <button className={styles.button} type="submit" disabled={!formValue}>
                    Ask
                    <img className={styles.sendIcon} src={sendIconURL} alt="send icon" />
                </button>
            </form>
        </>
    );
}

function convertLineBreaksToBr(text) {
    return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}

const ChatMessage = React.memo(ChatMessageComponent);

function ChatMessageComponent(props) {
    const { text, uid } = props.message;
    const photoURL = uid === -1 ? svgDataUrl : props.profilePicture;
    const messageClass = uid !== -1 ? styles.sent : styles.received;
    const textClass = uid !== -1 ? styles.sentText : styles.receivedText;
    const imageClass =
        uid === -1
            ? styles.receivedImg
            : photoURL === userIconUrl
            ? styles.defaultImg
            : styles.sentImg;
    const setAreas = props.setCurrentAreas;
    const setCurrentPageReference = props.setCurrentPageReference;
    const showInfoBanner = props.showInfoBanner;
    const [showCheckmark, setShowCheckmark] = useState(false);

    const cleanText = (message) => {
        let text = message.replace(/(<sup[^>]*>[^<]*<\/sup>)+$/, "");

        // Regular expression to match other HTML tags and capture their content
        let htmlTagRegex = /<[^>]*>([^<]*)<\/[^>]*>/g;

        // Replace all other HTML tags with their inner content
        text = text.replace(htmlTagRegex, "$1");

        // Regular expression to replace the Markdown image template with the caption
        let markdownImageRegex = /\[!\[([^\]]*)\]\([^\)]*\)\]\([^\)]*\)/g;

        // Replace the Markdown image template with just the caption
        let cleanText = text.replace(markdownImageRegex, "$1");
        return cleanText;
    };

    const toggleCheckmark = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            console.log(cleanText(text));
            navigator.clipboard
                .writeText(cleanText(text))
                .then(() => {
                    console.log("Text copied to clipboard successfully!");
                    setShowCheckmark(true);
                    setTimeout(() => {
                        setShowCheckmark(false);
                    }, 3000);
                })
                .catch((err) => {
                    showInfoBanner("Failed to copy text: ");
                });
        } else {
            showInfoBanner("Clipboard API not available");
        }
    };

    let processedText = convertLineBreaksToBr(text);

    // Check for the special string
    const upgradeString = "Click here to upgrade now";

    if (text.includes(upgradeString) && uid === -1) {
        // Split the text and insert the clickable part
        const parts = text.split(upgradeString);
        processedText = (
            <>
                {parts[0]}
                <span
                    onClick={props.toggleUpgradeModal}
                    style={{ color: "blue", cursor: "pointer" }}>
                    {upgradeString}
                </span>
                {parts[1]}
            </>
        );
    }

    if (!text.trim()) {
        return null;
    }

    const displayUpgradeMessage = text.includes(upgradeString) && uid === -1 ? true : false;

    const customHeading = {
        h1: ({ node, ...props }) => <h1 className={styles.h1LineHeight} {...props} />,
    };

    const customImage = {
        a: ({ node, ...props }) => {
            if (props.href && props.children && props.children.props && props.children.props.src) {
                // If the <a> tag has a href attribute and wraps around an <img> tag, render it without the href attribute
                return React.cloneElement(props.children, { href: null });
            } else {
                // If the <a> tag doesn't wrap around an <img> tag, render it normally
                return <a {...props} />;
            }
        },
        img: ({ node, ...props }) => (
            <figure style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <img
                        {...props}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "256px",
                            minWidth: "100px",
                            alignItems: "center",
                            pointerEvents: "none",
                        }}
                    />
                </div>
                {props.alt && (
                    <figcaption
                        style={{
                            textDecoration: "none",
                            cursor: "default",
                            textAlign: "center",
                            color: "gray",
                        }}>
                        {props.alt}
                    </figcaption>
                )}
            </figure>
        ),
        abbr: ({ node, ...props }) => {
            return (
                <abbr
                    onClick={() => {
                        setCurrentPageReference(props.children);
                        const Areas = JSON.parse(props["data-id"]);
                        Areas.forEach((area) => {
                            area["pageIndex"] = parseInt(props.children) - 1;
                        });
                        setAreas(Areas);
                    }}
                    className={styles.reference}
                    {...props}
                />
            );
        },
        sup: ({ node, ...props }) => {
            return "";
        },
        sub: ({ node, ...props }) => {
            return (
                <sup>
                    {showCheckmark ? (
                        <CheckIcon />
                    ) : (
                        <ClipBoardIcon handleClick={toggleCheckmark}></ClipBoardIcon>
                    )}
                </sup>
            );
        },
    };

    const renderedText =
        text +
        "<sup>{showCheckmark ? <CheckIcon/> : <ClipBoardIcon handleClick={toggleCheckmark}></ClipBoardIcon>} </sup>";
    return (
        <>
            <div className={`${styles.message} ${messageClass}`}>
                <img className={`${styles.img} ${imageClass}`} src={photoURL} alt="chat avatar" />
                {displayUpgradeMessage ? (
                    <p
                        className={`${styles.p} ${textClass}`}
                        style={{ paddingTop: "14px", paddingBottom: "14px" }}
                        translate="no">
                        {processedText}
                    </p>
                ) : (
                    <p className={`${styles.p} ${textClass}`} translate="no">
                        <ReactMarkdown
                            className={styles.p}
                            components={{ ...customHeading, ...customImage }}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex, rehypeRaw]}>
                            {uid !== -1 ? `${text}` : `${text}` + "<sub>1</sub>"}
                        </ReactMarkdown>
                    </p>
                )}
            </div>
        </>
    );
}

export default ChatRoom;

import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import styles from './flashcardcontainer.module.css'
import Flashcard from './Flashcard';
import { v4 as uuidv4 } from 'uuid';
import ResetButton from './ResetButton';
import FinishCard from './FinishCard';

const CorrectIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" class={styles.optionIcon}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
);

const IncorrectIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" class={styles.optionIcon}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
);

const uncoverIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" class={styles.optionIcon}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
);

const restartIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" class={styles.optionIcon}>
    <   path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
    </svg>
);


const initialValue = `<p>Content of your card</p>
<ul>
    <li>Example item in the list</li>
    <li>More examples</li>
</ul>`

const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const SAVE_FLASHCARDS_ENDPOINT = BASE_API_ADDRESS + "/generate_upload_link_flashcards";
const SAVE_FLASHCARDS_TITLE_ENDPOINT = BASE_API_ADDRESS + "/save_flashcards_title";

const save_flashcards = async (document_id, user_id, flashcards, flashcardsLogs, flashcardStatus, uploadFileToS3) => {
    try {
      //console.log("document_id", document_id)
      //console.log("user_id", user_id)
      //console.log(instructions);
      const response = await axios.get(SAVE_FLASHCARDS_ENDPOINT,{ params: { document_id: document_id, user_id: user_id} });
      //console.log(response.data);
      const presignedUrl = response.data.presigned_url;
      console.log(presignedUrl)

      const cards = {
        status: flashcardStatus,
        flashcards: flashcards,
        logs: flashcardsLogs
      }
      
      const flashcardsJson = JSON.stringify(cards);
      const file = new Blob([flashcardsJson], { type: 'application/json' });

      await uploadFileToS3(presignedUrl, file);
      console.log('Flashcards Saved')
    } catch (error) {
      console.log(error)
    }
  };

const save_flashcards_title = async(document_id, flashcards_title) => {
    try {
      const response = await axios.get(SAVE_FLASHCARDS_TITLE_ENDPOINT,{ params: { document_id: document_id, flashcards_title: flashcards_title} });
      console.log('Flashcards Title Saved')
    } catch (error) {
      console.log(error)
    }
  };

const AddCardContainer = ({id, modifyCard}) => {
    const useLazyLoad = (ref, options = {}) => {
        const [isVisible, setIsVisible] = useState(false);
      
        useEffect(() => {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
                observer.unobserve(ref.current); // Stop observing once loaded
              }
            });
          }, options);
      
          if (ref.current) {
            observer.observe(ref.current);
          }
      
          return () => {
            if (ref.current) {
              observer.disconnect();
            }
          };
        }, [ref, options]);
      
        return isVisible;
      };
  
      const ref = useRef();
      const isVisible = useLazyLoad(ref);

    return (
        <div ref={ref} className={styles.ref}>
            {isVisible && (
            <div className={styles.buttonContainer} onClick={() => modifyCard(id, "createCard")}>
                <div className={styles.button}>
                    <div className={styles.plusHorizontal}></div>
                    <div className={styles.plusVertical}></div>
                </div>
            </div>
            )}
        </div>
    );
}

const FlashCardContainer = ({document_id, user_id, uploadFileToS3, isStudyModeActive, numberOfRepetitions, flashcardLogs, setFlashCardLogs, isResetModalOpen, toggleResetModal, flashcards_title, setFlashCardsTitle, flashcards, setFlashCards, flashcardStatus, isPaidUser, toggleUpgradeModal, handleFlashcardCreation}) => {
    const [formValue, setFormValue] = useState(flashcards_title);
    const [instructions, setInstructions] = useState([]);
    const [savingCardIds, setSavingCardIds] = useState([]);
    const [finishedSavingCardIds, setFinishedSavingCardIds] = useState([]);
    const [fullView, setFullView] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isShowingAnalytics, setIsShowingAnalytics] = useState(false);
    const [successCounts, setSuccessCounts] = useState({0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0});
    const [didOneMove, setDidOneMove] = useState(false);
    

    const shuffleArray = array => {
        return array.sort(() => Math.random() - 0.5);
      };
      
    const [sortedAndShuffledFlashcards, setSortedAndShuffledFlashcards] = useState(Object.values(flashcards
        .reduce((acc, card) => {
            acc[card.correct] = acc[card.correct] || [];
            acc[card.correct].push(card);
            return acc;
        }, {}))
        .map(group => shuffleArray(group))
        .flat());
    
    const [currentCard, setCurrentCard] = useState(sortedAndShuffledFlashcards[0]);

    useEffect(() => {
        setCurrentCard(sortedAndShuffledFlashcards[0]);
        if(sortedAndShuffledFlashcards[0].correct == numberOfRepetitions && didOneMove == true){
            const currentDate = new Date();
            const dateString = currentDate.toLocaleDateString();
            const newLog = {'mode': numberOfRepetitions, 'date': dateString}
            setFlashCardLogs(prevLogs => [...prevLogs, newLog]);
            modifyCard(null,"addLog",null,null,newLog);
        }
      }, [sortedAndShuffledFlashcards]);
    
    useEffect(() => {
        setIsFinished(sortedAndShuffledFlashcards[0].correct >= numberOfRepetitions ? true : false)
    }, [sortedAndShuffledFlashcards, numberOfRepetitions])

    useEffect(() => {
        let newCounts = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
    
        for (let i = 0; i <= 5; i++) {
          newCounts[i] = flashcards.filter(card => card.correct == i).length;
        }

        //console.log(newCounts)
    
        setSuccessCounts(newCounts);
      }, [sortedAndShuffledFlashcards]);
    
    const UpgradeCard = ({firstText}) => {
        return (
            <div className={styles.uploadContainer}>
                <p style={{ marginTop: '50px' }}></p>
              {firstText.split('\n').map((paragraph, index) => (
                    <p className={styles.uploadTextSubtitle} key={index}>{paragraph}</p>
                ))}
              <div className={styles.uploadArea}>
                Upgrade to get flashcards for the full document
                <button className={styles.uploadBtn} onClick={toggleUpgradeModal}>
                Upgrade now!
              </button>
              </div>
            </div>
          );
    }

    const RegenerateCard = () => {
        return (
            <div className={styles.uploadContainer}>
                <p style={{ marginTop: '50px' }}></p>
              <h3 className={styles.uploadTextSubtitle}>
                Congratulations for upgrading to a premium plan
              </h3>
              <div className={styles.uploadArea}>
                Choose the language of your full set of flashcards
                <div className={styles.buttons}>
                    <button className={styles.uploadBtn} onClick={() => handleFlashcardCreation(document_id, "English", null, null, true)}>
                        English
                    </button>
                    <button className={styles.uploadBtn} onClick={() => handleFlashcardCreation(document_id, "German", null, null, true)}>
                        German
                    </button>
                </div>
              </div>
            </div>
          );
    }

    const MenuButton = ({icon, text, onClick}) => {

        let additionalClass;
    
        switch (text) {
            case "Correct":
                additionalClass = styles.correctButtonGradient;
                break;
            case "Incorrect":
                additionalClass = styles.incorrectButtonGradient;
                break;
            case "Uncover":
                additionalClass = styles.uncoverButtonGradient;
                break;
            default:
                additionalClass = styles.defaultButtonGradient;
                break;
        }


        const buttonClass = `${styles.menuButton} ${additionalClass}`; 


        return (
        <button className={buttonClass} onClick={onClick} >
            <span className={styles.optionIcon}>{icon} </span>
            <span className={styles.optionText}>{text}</span>
        </button>
        );
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (instructions.length > 0) {
                //console.log(JSON.stringify(instructions));
                save_flashcards(document_id, user_id, flashcards, flashcardLogs, flashcardStatus, uploadFileToS3);
                setInstructions([]);
                //console.log(finishedSavingCardIds);
                setSavingCardIds([]);
                setTimeout(() => {
                    setFinishedSavingCardIds([]);
                }, 2000);  
            }
        }, 2000);

        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [instructions]);

    const handleChange = (e) => {
        setFormValue(e.target.value);
    }
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && e.target.value !== flashcards_title) {
          setFlashCardsTitle(e.target.value)
          save_flashcards_title(document_id, e.target.value)
          e.preventDefault(); // Prevent the default action of inserting a new line
        }
      };
    
      const handleBlur = (e) => {
        if (e.target.value !== flashcards_title) {
          setFlashCardsTitle(e.target.value);
          save_flashcards_title(document_id, e.target.value);
        }
      };

      const toggleFullView = () => {
        setFullView(!fullView);
      }

      const toggleShowAnalytics = () => {
        setIsShowingAnalytics(!isShowingAnalytics);
      }
      

      const handleCardMarked = (cardId, increment) => {
        // Find the index of the card
        const cardIndex = sortedAndShuffledFlashcards.findIndex(card => card.id === cardId);
        if (cardIndex === -1) return; // card not found
    
        // Update the card's 'correct' value
        const updatedCard = {
          ...sortedAndShuffledFlashcards[cardIndex],
          correct: sortedAndShuffledFlashcards[cardIndex].correct + increment,
          incorrect: sortedAndShuffledFlashcards[cardIndex].incorrect + (1-increment)
        };
      
        // Remove the card from its current position
        const newFlashcards = sortedAndShuffledFlashcards.filter((_, index) => index !== cardIndex);
        //console.log("Flashcards:", sortedAndShuffledFlashcards);

        const firstMatchingScoreIndex = newFlashcards.findIndex(card => card.correct === updatedCard.correct);

        const startIndex = (firstMatchingScoreIndex === -1 && increment > 0) ? newFlashcards.length : firstMatchingScoreIndex;
        const endIndex = newFlashcards.findIndex(card => card.correct > updatedCard.correct);
        const targetIndexRange = endIndex === -1 ? newFlashcards.length : endIndex;

        //console.log("Start Index:",startIndex);
        //console.log("End Index:", endIndex);
        //console.log("Target Range:", targetIndexRange);
        
        const fixedPosition = 0;
        // Calculate the absolute random position. For last two cards choose a random number between 0 and 1
        const randomPosition = (startIndex === 0 && endIndex === 1) ? Math.random() < 0.5 ? 0 : 1 :startIndex + Math.floor(Math.random() * (targetIndexRange - startIndex));

        console.log("Final Index:", randomPosition)
        // Insert the card at the new position using ternary operator. If hit inccorect on last card to be learned show that card again
        newFlashcards.splice((startIndex === -1 && endIndex === 0) ? fixedPosition : randomPosition, 0, updatedCard);
      
        // Update the state or variable holding the flashcards
        setSortedAndShuffledFlashcards(newFlashcards); // Assuming you're using React state
      };

      const handleRestart = () => {
        const newCards = shuffleArray(sortedAndShuffledFlashcards.map(card => ({ ...card, correct: 0, incorrect: 0 })));
        setSortedAndShuffledFlashcards(newCards);
      }

      //This function does a hard comparison of the two contents from cards
      const isContentEqual = (first, second) => {return JSON.stringify(first) === JSON.stringify(second)}

      const modifyCard = (id, action, headline, content, newLog) => {
        const index = flashcards.findIndex(card => card.id === id);
    
        const updateInstructions = (actionType, cardDetails) => {
            setInstructions(prevInstructions => [
                ...prevInstructions,
                { action: actionType, details: cardDetails }
            ]);
        }
    
        if(action == "saveCard"){
            const card = flashcards.find(card => card.id === id);
            if(headline === card.headline && isContentEqual(content, card.content)){
                console.log("Nothing to change");
                return;
            }
            setSavingCardIds(prevCards =>[
                ...prevCards, id
            ]);
            setFinishedSavingCardIds(prevCards =>[
                ...prevCards, id
            ]);
            const newFlashcards = flashcards.map(card => {
                if (card.id === id) {
                    return { ...card, headline: headline, content: content };
                }
                return card;
            });
            setFlashCards(newFlashcards);
            updateInstructions("saveCard", { id, headline, content });
        }
    
        if(action == "deleteCard"){
            const newFlashcards = flashcards.filter(card => card.id !== id);
            setFlashCards(newFlashcards);
            updateInstructions("deleteCard", { id });
            if(newFlashcards.length === 0){
                const newId = uuidv4();
                const newCard = { id: newId, headline: '', content: initialValue };
                const newFlashcards = [newCard];
                setFlashCards(newFlashcards);
                updateInstructions("createCard", { id: id, newId: newId });
            }
        }
    
        if(action == "createCard"){
            const newId = uuidv4();
            const newCard = { id: newId, headline: '', content: initialValue, correct: 0, incorrect: 0 };
            const newFlashcards = [...flashcards];
            newFlashcards.splice(index + 1, 0, newCard);
            setFlashCards(newFlashcards);
            updateInstructions("createCard", { id: id, new_id: newId });
        }

        if(action == "increaseCorrect"){
           handleCardMarked(id, 1)
           toggleFullView();
           setDidOneMove(true);

           const newFlashcards = flashcards.map(card => {
            if (card.id === id) {
                return { ...card, correct: card.correct + 1};
            }
            return card;
            });
            setFlashCards(newFlashcards);
            updateInstructions("increaseCorrect", { id: id });
        }

        if(action == "increaseIncorrect"){
            handleCardMarked(id, 0)
            toggleFullView();
            setDidOneMove(true);
 
            const newFlashcards = flashcards.map(card => {
             if (card.id === id) {
                 return { ...card, incorrect: card.incorrect + 1};
             }
             return card;
             });
             setFlashCards(newFlashcards);
             updateInstructions("increaseIncorrect", { id: id });
         }

         if(action == "reset"){
            handleRestart();
 
            const newFlashcards = flashcards.map(card => ({ ...card, correct: 0, incorrect: 0 }))
            setFlashCards(newFlashcards);
            updateInstructions("reset", {});
         }

         if(action == "addLog"){
            updateInstructions("addLog", {log: newLog})
         }
    }
    
        
    return (
        <>
            {!isStudyModeActive && (
                <div className={styles.container}>
                    <div className={styles.title}>
                        <input
                            className={styles.input}
                            value={formValue}
                            onChange={(e) => handleChange(e)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onBlur={(e) => handleBlur(e)}
                            onFocusOut={(e) => handleBlur(e)}
                            onMouseLeave={(e) => handleBlur(e)}
                            placeholder="Title of your deck"
                        />
                    </div>
                    {flashcards.map((card, index) => (
                        <React.Fragment key={card.id}>
                            <Flashcard 
                                card={card} 
                                modifyCard={modifyCard} 
                                savingCardIds={savingCardIds} 
                                finishedSavingCardIds={finishedSavingCardIds} 
                                cardNumber={index + 1} 
                                numberOfCards={flashcards.length} 
                            />
                            <AddCardContainer id={card.id} modifyCard={modifyCard} />
                        </React.Fragment>
                    ))}
                    {flashcardStatus === 'exceeded limit' && !isPaidUser && (
                        <UpgradeCard 
                            firstText={"Those were your first 5 sets of flashcards"} 
                            secondText={"Upgrade now to generate unlimited sets of flashcards"} 
                        />
                    )}
                    {flashcardStatus === 'incomplete' && !isPaidUser && (
                        <UpgradeCard 
                            firstText={"Those were the flashcards for the first\n 1000 words of your document"} 
                            secondText={"Upgrade now to generate flashcards for the full document"} 
                        />
                    )}
                    {(flashcardStatus === 'incomplete' || flashcardStatus === 'exceeded limit') && isPaidUser && (
                        <RegenerateCard />
                    )}
                </div>
            )}
    
            {isStudyModeActive && flashcards.length > 0 && (
                <div className={styles.containerStudyMode}>
                    <div className={styles.data}>
                        <span className={styles.dataTitle}> Card Deck:</span>
                        {flashcards_title || "Title of your deck"}
                    </div>
                    <div className={styles.stats}> 
                        <span className={styles.dataTitle}>Total cards: {flashcards.length}</span>
                        <span className={styles.dataTitle}></span>
                        <span className={styles.dataTitle}>Successfully learned: {((flashcards.length - successCounts[0]) / (numberOfRepetitions * flashcards.length) * 100).toFixed(2)}%</span>
                        <span className={styles.dataTitle}></span>
                        <span className={styles.analyticsTitle} onClick={toggleShowAnalytics}>Click here for analytics</span>
                    </div>
                    {isShowingAnalytics && (
                    <div className={styles.analytics}>
                        <div className={styles.smallAnalyticsTitle}>Analytics</div>
                        <>
                          {
                            Object.entries(successCounts).map(([key, value]) => (
                             <div className={styles.smallAnalyticsText} key={key}>
                                {key}x successfully learned: {value}/{flashcards.length} ({flashcards.length == 0 ? 0 : Math.floor(value/flashcards.length * 100) }%)
                             </div>
                           ))
                          }
                        </>
                        <div className={styles.separator}></div>
                        <div className={styles.smallAnalyticsTitle}>Logs</div>
                        <>
                        {flashcardLogs.slice(-5).map((log, index) => (
                            <div key={index} className={styles.smallAnalyticsLog}>
                                Last Time finished in mode Repeat {log.mode}x on: {log.date || 'N/A'}
                            </div>
                        ))}
                        </>
                    </div>
                    )}
                    {isResetModalOpen && (
                        <ResetButton
                            isVisible={isResetModalOpen}
                            onReset={() => {modifyCard(null, "reset")
                                            toggleResetModal()}}
                            onClose={toggleResetModal}
                        />
                    )}
                    <div className={styles.cardContainer}>
                        {isFinished ? (
                            <FinishCard numberOfRepetitions={numberOfRepetitions}/>
                        ): (
                        <Flashcard
                            key={currentCard.id}
                            card={currentCard} 
                            isStudyModeActive={isStudyModeActive} 
                            modifyCard={modifyCard} 
                            savingCardIds={savingCardIds} 
                            finishedSavingCardIds={finishedSavingCardIds} 
                            cardNumber={flashcards.findIndex(card => card.id === currentCard.id)+1} 
                            numberOfCards={flashcards.length}
                            numberOfRepetitions={numberOfRepetitions}
                            fullView={fullView}
                        />
                        )}
                        <div className={styles.menuContainer}>
                            {isFinished && (
                                <MenuButton icon={restartIcon} text={"Restart"} onClick={toggleResetModal}/>
                            )}

                            {!isFinished && fullView &&(
                              <>
                                <MenuButton icon={CorrectIcon} text={"Correct"} onClick={() => {modifyCard(currentCard.id, "increaseCorrect")}}/>
                                <MenuButton icon={IncorrectIcon} text={"Incorrect"} onClick={() => {modifyCard(currentCard.id, "increaseIncorrect")}}/>
                              </>
                            )}
                            {!isFinished && !fullView && (
                                <MenuButton icon={uncoverIcon} text={"Uncover"} onClick={toggleFullView}/>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );    
}

export default FlashCardContainer;